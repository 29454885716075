/* eslint-disable vue/valid-v-for */
<template>
  <v-container class="mt-8">
    <div>
      <v-row wrap align="center" justify="center">
        <v-col md="12">
          <v-alert v-model="alert" v-if="alert_show && candidate.subcribe_date >= 0 && candidate.subcribe_date <= 5" dismissible color="white"
            border="left" elevation="2" colored-border icon="mdi-information"
            style="background-color: red; color: #fff; border-width: 1px;">
            A sua subscrição para visualização de Concursos e Pedidos de Cotações expira em <strong>{{
              candidate.subcribe_date }} dias</strong>! <a style="color: #FFF" @click="goToSubscricao()"><strong>Saber
                mais</strong></a>
          </v-alert>
          <v-alert v-model="alert" v-if="alert_show && candidate.subcribe_date < 0" dismissible color="white"
            border="left" elevation="2" colored-border icon="mdi-information"
            style="background-color: red; color: #fff; border-width: 1px;">
            A sua subscrição para a visualização de concursos e pedidos de cotações expirou. Apartir de 50MT <a style="color: #FFF" @click="goToSubscricao()"><strong>Actualize aqui</strong></a>
          </v-alert>
          <!-- <template>
            <v-chip x-small label href="https://gugla.co.mz/publicidade.html" dark :color="'green darken-1'">anuncie neste
              espaço</v-chip>
          </template>
          <publicity /> -->
        </v-col>
      </v-row>

      <v-row wrap align="center" justify="space-between">
        <v-col cols="12" sm="12" md="8" class="col-md-3">
          <h4 style="text-transform: uppercase; font-size: 20px; color: #ff5252">
            Todos os Concursos
          </h4>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="col-md-3">
          <search-content-component @searchContentEvent="searchContentEvent"></search-content-component>
        </v-col>
      </v-row>

      <v-row v-if="search_alert">
        <v-col cols="12">
          <v-alert v-if="apiContents.total > 0" dense text type="success" border="left">
            <v-row justify="space-between">
              <v-col>
                <span class="caption">{{ apiContents.total }} resultados de {{ search }}.
                </span>
              </v-col>
              <v-col class="text-right">
                <v-btn @click="resetSearchResults" color="success" small text>Limpar <v-icon
                    small>mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert v-else dense text type="error" border="left">
            <v-row justify="space-between">
              <v-col>
                <span class="caption">{{ apiContents.total }} resultados de {{ search }}.
                </span>
              </v-col>
              <v-col class="text-right">
                <v-btn @click="resetSearchResults" color="error" small text>Limpar <v-icon
                    small>mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>

      <v-row wrap>
        <v-col cols="12">
          <v-row wrap>
            <!-- <v-col sm="12" md="6" class="col-md-5 mb-3">
              <v-card class="mx-auto d-flex flex-column" elevation="1" min-height="180px" color="#F5F5F3" dark
                :to="{ name: 'recruiter.contents.create' }">
                <v-list-item three-line>
                  <v-list-item-content>
                    <h3 style="color: #111; font-weight: normal;">Sabia que pode submeter um <b
                        style="color: #ff5252;">Pedido de Cotações</b> ou um <b style="color: #ff5252;">Concurso
                        Público</b> ?</h3>
                    <p class="text-subtitle-2 mb1" style="color: #111">
                      Submeta o seu <b>Pedido de Cotações</b> ou <b>Concurso Público</b> nesta plataforma.
                      <v-btn small rounded color="#FF4500" dark elevation="2">Saber Mais</v-btn>
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col> -->
            <v-col sm="12" md="6" v-for="content in dados" :key="content.id" class="col-md-5 mb-3">
              <main-content-component :content="content" />
            </v-col>
            
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-pagination v-model="currentPage" :length="apiContents.last_page" :total-visible="7" @input="handleChangePage"
            color="error"></v-pagination>
        </v-col>
      </v-row>

      <v-row wrap align="center" justify="center">
        <v-col md="12" style="margin-bottom: 40px">
          <!-- <template>
            <v-chip x-small label href="https://gugla.co.mz/publicidade.html" dark :color="'green darken-1'">anuncie neste
              espaço</v-chip>
          </template>
          <publicity /> -->
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import searchContentComponent from "./content/searchContentComponent.vue";
import searchMobileComponent from "./content/searchMobileComponent.vue";
import mainContentComponent from "./content/mainContentComponent.vue";

import api from "../services/index.js";
import Publicity from "./publicity/publicity.vue";

export default {
  components: {
    searchContentComponent,
    // eslint-disable-next-line vue/no-unused-components
    searchMobileComponent,
    mainContentComponent,
    Publicity,
  },

  data() {
    return {
      dados: [],
      publicidades: [],
      alert_show: false,
      data_subscricao: "",
      apiContents: [],
      currentPage: 1,
      search: "",
      search_alert: false,
      value: true,
      local: "",
      categoria: "",
      items: [
        "Cabo Delgado",
        "Gaza",
        "Inhambane",
        "Manica",
        "Maputo",
        "Nampula",
        "Niassa",
        "Sofala",
        "Tete",
        "Zambeze",
      ],
      c: 1,
      apiCategories: [], //category_name, category_count
      catgoriaFilter: "",
      categorias: [
        "Administração e Secretariado Cliente",
        "Aquisições e Procurement",
        "Assistente",
        "Auditoria e Consultoria",
        "Comunicação Social",
        "Construção Civil",
        "Contabilidade e Finanças",
        "Design",
        "Direito e Justiça",
        "Electricidade",
        "Gestão de Projectos",
        "Gestão e Assessoria",
        "Gestão Financeira",
        "Gestor Provincial",
        "Higiene e Segurança",
        "Hotelaria e Turismo",
        "informática e Programação",
        "Manutenção e Técnica",
        "Marketing e Publicidade",
        "Mecânica",
        "Meio Ambiente",
        "Monitoria e Avaliação",
        "Oficial Técnico",
        "Operário",
        "Recursos Humanos",
        "Relações Públicas",
        "Saúde",
        "Seguros",
        "Serviços Gerais",
        "Serviços Sociais",
        "Supervisão e Coordenação",
        "Topografia e Geologia",
        "Transportes e Logística",
        "Vendas/Comércio",
        "outro",
      ],
      hideFilter: false,
    };
  },
  computed: {
    candidate() {
      let candidate = this.$store.state.candidate;
      this.data_subscricao = candidate.subcribe_date
      return candidate;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndUp ? true : false;
    },
    publicities() {
      return this.$store.state.publicities
    }
  },
  created() {
    // let token = localStorage.getItem("TOKEN_AUTH");
    // if (token) {
    //   this.alert_show = true
    // } else {
    //   this.alert_show = false
    // }
    this.$store.dispatch("me").then(res => {
      this.alert_show = true
    }).catch(error => {
      this.alert_show = false
    });
    this.$store.dispatch("loaderChange", true);

    if (sessionStorage.titulo) {
      this.search_alert = true;
      this.searchContentEvent(sessionStorage.titulo);
    } else if (sessionStorage.province) {
      this.search_alert = true;
      this.filterByProvince(sessionStorage.province);
    } else if (sessionStorage.categoria) {
      this.search_alert = true;
      this.filterByCategoria(sessionStorage.categoria);
    } else if (Number(sessionStorage.page)) {
      this.currentPage = Number(sessionStorage.page);
      this.getContents(Number(sessionStorage.page));

    } else {
      this.getContents(this.currentPage);

    }
    if (localStorage.getItem("token")) {
      this.$store
        .dispatch("me")
        .then(() => {
          this.$store.dispatch("loaderChange", false);
        })
        .catch((err) => {
          this.$store.dispatch("logout");
          this.$router.push({ name: "candidate.login" });
          this.$store.dispatch("loaderChange", false);
        });
    }
    this.getCategories();
  },
  methods: {
    goToSubscricao() {
      this.$router.push({ name: 'site.available.plans' })
    },
    getEndPoint() {
      const token = localStorage.getItem('TOKEN_AUTH')
      // if (token) {
      //   return {
      //     endPoint: "/recruiters/v1/auth/contents",
      //     token: token
      //   }
      // } else {
      //   return {
      //     endPoint: "/recruiters/v1/contents",
      //     token: token
      //   }
      // }
        return {
          endPoint: "/recruiters/contents",
          token: token
        }
    },
    getContents(page) {
      const endpoint = this.getEndPoint();
      api.get(`${endpoint.endPoint}?page=${page}&?titulo=${this.search}`).then((res) => {
        this.apiContents = res.data.data;
        this.publicidades = this.$store.state.publicities
        let novo_array = this.apiContents.data
        let publicity_two_items = [...this.publicidades].sort(() => 0.5 - Math.random()).slice(0, 2);
        publicity_two_items.forEach((element) => {
          novo_array.splice(Math.floor(Math.random() * (novo_array.length + 1)), 0, element)
        })
        this.dados = novo_array
        this.$store.dispatch("loaderChange", false);

      });
    },
    checkUserProfile() {
      if (
        this.candidate?.personal_data &&
        this.candidate.personal_data == null
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (this.candidate?.anexos && this.candidate.anexos.length == 0) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (
        this.candidate?.education &&
        this.candidate.education.length == 0
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      } else if (
        this.candidate?.language &&
        this.candidate.language.length == 0
      ) {
        this.$store.dispatch("changeCompleteProfile", true);
      }
    },
    //get contents from next page
    handleChangePage(page) {
      this.$store.dispatch("loaderChange", true);
      this.currentPage = page;
      sessionStorage.page = page;
      if (!this.search && !this.local) {
        this.getContents(this.currentPage)
      } else if (this.search) {
        //get next page searched contents
        this.getContents(this.currentPage)
      }
    },
    showPesquisa() {
      console.log("ok");
      this.hideFilter = true;
    },

    searchContentEvent(filter) {
      const endpoint = this.getEndPoint();
      sessionStorage.titulo = filter;
      this.$store.dispatch("loaderChange", true);
      if (this.search_alert == false) {
        sessionStorage.removeItem("page");
      }
      if (Number(sessionStorage.page)) {
        api
          .get(
            `${endpoint.endPoint}?titulo=${filter}&page${sessionStorage.page}`
          )
          .then((res) => {
            this.currentPage = Number(sessionStorage.page);
            this.apiContents = res.data.data;
            // console.log(this.apiContents);
            this.publicidades = this.$store.state.publicities
            let novo_array = this.apiContents.data
            let publicity_two_items = [...this.publicidades].sort(() => 0.5 - Math.random()).slice(0, 2);
            publicity_two_items.forEach((element) => {
              novo_array.splice(Math.floor(Math.random() * (novo_array.length + 1)), 0, element)
            })
            this.dados = novo_array
            this.search_alert = true;
            this.search = filter;
            this.$store.dispatch("loaderChange", false);
          });
      } else {
        api.get(`${endpoint.endPoint}?titulo=${filter}`).then((res) => {
          this.currentPage = 1;
          this.apiContents = res.data.data;
          this.publicidades = this.$store.state.publicities
          let novo_array = this.apiContents.data
          let publicity_two_items = [...this.publicidades].sort(() => 0.5 - Math.random()).slice(0, 2);
          publicity_two_items.forEach((element) => {
            novo_array.splice(Math.floor(Math.random() * (novo_array.length + 1)), 0, element)
          })
          this.dados = novo_array
          this.search_alert = true;
          this.search = filter;
          this.$store.dispatch("loaderChange", false);
        });
      }
    },

    resetSearchResults() {
      this.currentPage = 1;
      this.$store.dispatch("loaderChange", true);
      this.search = "";
      this.local = "";
      this.categoria = "";
      this.catgoriaFilter = "";
      // sessionStorage.clear();
      sessionStorage.removeItem("titulo");
      sessionStorage.removeItem("page");
      this.search_alert = false;
      this.getContents();
    },
    filterByProvince(province) {
      sessionStorage.province = province;

      this.$store.dispatch("loaderChange", true);
      const endpoint = this.getEndPoint();
      if (this.search_alert == false) {
        sessionStorage.removeItem("page");
      }
      if (Number(sessionStorage.page)) {
        api
          .get(
            `${endpoint.endPoint}?local=${province}&page=${Number(
              sessionStorage.page
            )}`
          )
          .then((res) => {
            this.currentPage = Number(sessionStorage.page);
            this.apiContents = res.data.data;
            this.local = province;
            this.search_alert = true;
            this.search = province;
            this.$store.dispatch("loaderChange", false);
          });
      } else {
        api.get(`${endpoint.endPoint}?local=${province}`).then((res) => {
          this.currentPage = 1;
          this.apiContents = res.data.data;
          this.local = province;
          this.search_alert = true;
          this.search = province;
          this.$store.dispatch("loaderChange", false);
        });
      }
    },
    filterByCategoria(params) {
      sessionStorage.categoria = params;
      this.$store.dispatch("loaderChange", true);
      const endpoint = this.getEndPoint();
      if (this.search_alert == false) {
        sessionStorage.removeItem("page");
      }
      if (Number(sessionStorage.page)) {
        api
          .get(
            `${endpoint.endPoint}?industria=${params}&page=${Number(
              sessionStorage.page
            )}`
          )
          .then((res) => {
            this.currentPage = Number(sessionStorage.page);
            this.apiContents = res.data.data;
            this.search_alert = true;
            this.catgoriaFilter = params;
            this.search = params;
            this.$store.dispatch("loaderChange", false);
          });
      } else {
        api.get(`${endpoint.endPoint}?industria=${params}`).then((res) => {
          this.currentPage = 1;
          this.apiContents = res.data.data;
          this.search_alert = true;
          this.catgoriaFilter = params;
          this.search = params;
          this.$store.dispatch("loaderChange", false);
        });
      }

      // console.log(params)
    },
    getCategories() {
      api.get("/recruiters/categories").then((res) => {
        this.apiCategories = res.data.data;
        // console.log(res.data.data)
      });
    },
  },
};
</script>

<style>
.span-color {
  color: rgb(136, 136, 136);
}

.listCategory {
  font-size: 0.7em;
  cursor: pointer;
  margin-top: 5px;
}

ul li:hover {
  color: rgb(136, 136, 136);
}

/* .v-alert__border {
    border-style: solid;
    border-width: 45px;
    content: "";
    position: absolute;
} */
</style>