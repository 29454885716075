<template>
    <div id="generic_price_table">
      <!-- popup sobre login e subscricao  -->
      <v-dialog v-model="dialog" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            id="openPopSubscricao"
            style="display: none"
          >
            Open Dialog
          </v-btn>
        </template>
        <v-card
          class="mx-auto d-flex flex-column"
          style="padding-bottom: 0px; padding-top: 50px"
        >
          <div
            class="meuCard"
            style="
              margin: 0;
              padding-bottom: 0px;
              overflow-x: hidden;
              overflow-y: hidden;
            "
          >
            <v-row wrap>
              <v-col cols="12" md="12">
                <div class="text-center">
                  <img
                    src="../../../assets/key-content.png"
                    alt=""
                    height="130"
                    width="130"
                    style="display: block; margin-left: auto; margin-right: auto"
                  />
                </div>
                <div class="textoCabelho">
                  <h1 style="font-weight: bold; color: #111" class="text-center">
                    Precisa fazer Login!
                  </h1>
                  <br />
                  <p class="text-center text-body-2 text-md-body-1">
                    Por favor faça o login com a sua conta para poder efectuar o
                    pagamento da sua subscrição. Caso ainda não tenha uma conta
                    por favor registe-se.
                  </p>
                  <!-- <h3 style="font-weight: bold; color: #ff4500">
                    Beneficios de ter uma Subscrição
                  </h3> -->
                </div>
  
                <br />
  
                <!-- <h3 style="font-weight: bold; color: #ff4500; margin: 10px">
                  Beneficios de ter uma Subscrição
                </h3>
  
                <v-list>
                  <v-list-item
                    v-for="(validation_note, index_validation_note) in this
                      .validation_notes"
                    :key="index_validation_note"
                  >
                    <v-list-item-icon>
                      <v-icon color="#111">
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="text-justify-left text-body-2 text-md-body-1">
                        {{ validation_note.description }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->
  
                <!-- <div class="text-center">
                  <v-btn color="#D5172A" dark rounded @click="goToSubscricao"
                    >Efectuar Login</v-btn
                  >
                </div> -->
                <!-- <p class="text-center">
                  Caso já tenha uma conta faça login clicando no botão
                  <strong>Login</strong> caso contrario registe-se na aplicação.
                </p> -->
  
                <v-card-actions v-if="buttonCard == false">
                  <v-row wrap justify="center">
                    <v-col cols="12" md="4">
                      <v-btn block color="#FF4500" dark rounded @click="goToLogin"
                        >Login</v-btn
                      >
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn block color="#EEEEEE" rounded @click="goToRegisto"
                        >Registo</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-card-actions v-if="buttonCard == true">
                  <v-row wrap justify="center">
                    <v-col cols="12" md="6">
                      <v-btn
                        block
                        color="#D8293B"
                        dark
                        rounded
                        @click="goToSubscricao"
                        >Efectuar a Subscrição</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-actions>
  
                <!-- <p class="text-center font-weight-bold">
                  Junte-se a mais de {{ total }} utilizadores subscritos
                </p> -->
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <!-- fim do popup login e subscricao -->
  
      <!-- popup sobre login e subscricao  -->
      <v-dialog persistent v-model="dialog2" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            id="openPop2"
            style="display: none"
          >
            Open Dialog
          </v-btn>
        </template>
        <v-card
          class="mx-auto d-flex flex-column"
          style="padding-bottom: 0px; padding-top: 10px"
        >
          <div
            class="meuCard"
            style="
              margin: 0;
              padding-bottom: 0px;
              overflow-x: hidden;
              overflow-y: hidden;
            "
          >
            <v-row wrap>
              <v-col cols="12" md="12">
                <v-card-title>
                  <v-img
                    :src="
                      require('../../../assets/img/Logo-Empresas-830x354.png')
                    "
                    max-width="150"
                    max-height="150"
                    alt="Gugla Empresas"
                    style="display: block; margin-left: auto; margin-right: auto"
                  />
                </v-card-title>
                <v-card-subtitle>Login</v-card-subtitle>
                <v-card-text>
                  <v-text-field v-model="formData.email" label="Email" />
                  <v-text-field
                    v-model="formData.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    hint="Mínimo de 7 caracteres"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-card-text>
  
                <br />
  
                <v-card-actions>
                  <!-- <v-row wrap justify="center">
                    <v-col cols="12" md="4"> </v-col>
                  </v-row> -->
                  <v-btn color="#FF4500" block dark rounded @click="goToLogin2"
                    >Entrar</v-btn
                  >
                  <!-- <v-spacer></v-spacer>
                  <v-btn depressed rounded text small @click="forgotPassword"
                    >Esqueceu a senha?</v-btn
                  > -->
                </v-card-actions>
  
                <!-- <p class="text-center font-weight-bold">
                  Junte-se a mais de {{ total }} utilizadores subscritos
                </p> -->
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <!-- fim do popup login e subscricao -->
  
      <section>
        <div class="container">
          <!--BLOCK ROW START-->
          <div class="row">
            <div class="col-md-4">
              <!--PRICE CONTENT START-->
              <div class="generic_content clearfix">
                <!--HEAD PRICE DETAIL START-->
                <div class="generic_head_price clearfix">
                  <!--HEAD CONTENT START-->
                  <div class="generic_head_content clearfix">
                    <!--HEAD START-->
                    <div class="head_bg"></div>
                    <div class="head">
                      <span>1 Mês</span>
                    </div>
                    <!--//HEAD END-->
                  </div>
                  <!--//HEAD CONTENT END-->
  
                  <!--PRICE START-->
                  <div class="generic_price_tag clearfix">
                    <span class="price">
                      <span class="sign">MZN</span>
                      <span class="currency">{{ price }}</span>
                      <!-- <span class="cent">.99</span> -->
                      <span class="month"></span>
                    </span>
                  </div>
                  <!--//PRICE END-->
                </div>
                <!--//HEAD PRICE DETAIL END-->
  
                <!--FEATURE LIST START-->
                <!-- <div class="generic_feature_list">
                            <ul>
                                <li><span>1 Mês</span></li>
                                  <li><span>Acesso aos Concursos</span></li>
                                  <li><span>24/7</span> Suporte</li>
                              </ul>
                          </div> -->
                <!--//FEATURE LIST END-->
  
                <!--BUTTON START-->
                <div class="generic_price_btn clearfix">
                  <a @click="comprar1">Comprar</a>
                </div>
                <!--//BUTTON END-->
              </div>
              <!--//PRICE CONTENT END-->
            </div>
  
            <div class="col-md-4">
              <!--PRICE CONTENT START-->
              <div class="generic_content active clearfix">
                <!--HEAD PRICE DETAIL START-->
                <div class="generic_head_price clearfix">
                  <!--HEAD CONTENT START-->
                  <div class="generic_head_content clearfix">
                    <!--HEAD START-->
                    <div class="head_bg"></div>
                    <div class="head">
                      <span>3 Meses</span>
                    </div>
                    <!--//HEAD END-->
                  </div>
                  <!--//HEAD CONTENT END-->
  
                  <!--PRICE START-->
                  <div class="generic_price_tag clearfix">
                    <span class="price">
                      <span class="sign">MZN</span>
                      <span class="currency">{{ price*3 }}</span>
                      <!-- <span class="cent">.99</span> -->
                      <span class="month"></span>
                    </span>
                  </div>
                  <!--//PRICE END-->
                </div>
                <!--//HEAD PRICE DETAIL END-->
  
                <!--FEATURE LIST START-->
                <!-- <div class="generic_feature_list">
                            <ul>
                                <li><span>3 Meses</span></li>
                                  <li><span>Acesso aos Concursos</span></li>
                                  <li><span>24/7</span> Suporte</li>
                              </ul>
                          </div> -->
                <!--//FEATURE LIST END-->
  
                <!--BUTTON START-->
                <div class="generic_price_btn clearfix">
                  <a @click="comprar2">Comprar</a>
                </div>
                <!--//BUTTON END-->
              </div>
              <!--//PRICE CONTENT END-->
            </div>
            <div class="col-md-4">
              <!--PRICE CONTENT START-->
              <div class="generic_content clearfix">
                <!--HEAD PRICE DETAIL START-->
                <div class="generic_head_price clearfix">
                  <!--HEAD CONTENT START-->
                  <div class="generic_head_content clearfix">
                    <!--HEAD START-->
                    <div class="head_bg"></div>
                    <div class="head">
                      <span>12 Meses</span>
                    </div>
                    <!--//HEAD END-->
                  </div>
                  <!--//HEAD CONTENT END-->
  
                  <!--PRICE START-->
                  <div class="generic_price_tag clearfix">
                    <span class="price">
                      <span class="sign">MZN</span>
                      <span class="currency">{{ price*12 }}</span>
                      <!-- <span class="cent">.99</span> -->
                      <span class="month"></span>
                    </span>
                  </div>
                  <!--//PRICE END-->
                </div>
                <!--//HEAD PRICE DETAIL END-->
  
                <!--FEATURE LIST START-->
                <!-- <div class="generic_feature_list">
                            <ul>
                                <li><span>12 Meses</span></li>
                                  <li><span>Acesso ilimitado aos Concursos</span></li>
                                  <li><span>24/7</span> Suporte</li>
                              </ul>
                          </div> -->
                <!--//FEATURE LIST END-->
  
                <!--BUTTON START-->
                <div class="generic_price_btn clearfix">
                  <a @click="comprar3">Comprar</a>
                </div>
                <!--//BUTTON END-->
              </div>
              <!--//PRICE CONTENT END-->
            </div>
          </div>
          <!--//BLOCK ROW END-->
        </div>
      </section>
      <div class="" style="height: 70px;"></div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        mes: "",
        formData: {
          email: "",
          password: "",
        },
        show1: false,
        password: "Password",
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 7 || "Mínimo de 7 caracteres",
          emailMatch: () => `The email and password you entered don't match`,
        },
        buttonCard: false,
        pacote: {},
        dialog: false,
        validation_notes: [
          {
            code: 1,
            description: ``,
          },
          {
            code: 2,
            description: `Envie o email que usa na plataforma e o comprovativo de pagamento para o número 844181881 (Whatsapp) ou para o email comercial@marrar.co.mz.`,
          },
          {
            code: 3,
            description: `Logo a seguir sua conta já estará activa para ver todos os concursos na plataforma Gugla Empresas.`,
          },
        ],
      };
    },
    props: {
      price: {
        required: true
      }
    },
  
    created() {
      let token = localStorage.getItem("TOKEN_AUTH");
      if (token) {
        this.buttonCard = true;
      } else {
        this.buttonCard = false;
      }
    },
  
    methods: {
      goToLogin2() {
        this.$store
          .dispatch("login", this.formData)
          .then(() => {
            if (this.mes == 1) {
              this.$router.push({
                name: "site.subscricao.passos",
                params: { valor: this.price, mes: 1 },
              });
            } else if (this.mes == 3) {
              this.$router.push({
                name: "site.subscricao.passos",
                params: { valor: this.price, mes: 3 },
              });
            } else if (this.mes == 12) {
              this.$router.push({
                name: "site.subscricao.passos",
                params: { valor: this.price, mes: 12 },
              });
            }
          })
          .catch(() => {
            console.log("erro ao logar");
          })
          .finally("Finalizou no componet");
      },
      goToRegisto() {
        this.$router.push({ name: "candidate.register" });
      },
      goToLogin() {
        this.dialog = false;
        var urlAtual = window.location.href;
        sessionStorage.setItem("url", urlAtual);
        // sessionStorage.setItem("previous_router", "subscricao");
        this.$router.push({name: 'candidate.login'})
        // document.getElementById("openPop2").click();
      },
      comprar1() {
        this.mes = 1;
        let token = localStorage.getItem("TOKEN_AUTH");
        if (!token) {
          document.getElementById("openPopSubscricao").click();
        } else {
          this.$router.push({
            name: "site.subscricao.passos",
            params: { valor: this.price, mes: 1 },
          });
        }
        // this.$emit("valor", { valor: 489.99, mes: 1 });
        // document.getElementById("openPop").click();
        // this.pacote = { valor: 499, mes: 1 };
      },
      comprar2() {
        this.mes = 3;
        let token = localStorage.getItem("TOKEN_AUTH");
        if (!token) {
          document.getElementById("openPopSubscricao").click();
        } else {
          this.$router.push({
            name: "site.subscricao.passos",
            params: { valor: this.price*3, mes: 3 },
          });
        }
  
        // document.getElementById("openPop").click();
        // this.pacote = { valor: 1470, mes: 3 };
        // this.$emit("valor", { valor: 1469.99, mes: 3 });
      },
      comprar3() {
        this.mes = 12;
        let token = localStorage.getItem("TOKEN_AUTH");
        if (!token) {
          document.getElementById("openPopSubscricao").click();
        } else {
          this.$router.push({
            name: "site.subscricao.passos",
            params: { valor: this.price*12, mes: 12 },
          });
        }
  
        // document.getElementById("openPop").click();
        // this.pacote = { valor: 5760, mes: 12 };
        // this.$emit("valor", { valor: 5879.99, mes: 12 });
      },
      onClose() {
        this.dialog = false;
      },
    },
  };
  </script>
  
  <style>
  /* ======================== */
  /*   Syed Sahar Ali Raza    */
  /* ======================== */
  @import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
  @import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
  @import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
  body {
    background-color: #eee;
  }
  
  .textoCabelho {
    margin-top: 40px;
  }
  
  /* #generic_price_table{
    background-color: #f0eded;
  } */
  
  /*PRICE COLOR CODE START*/
  #generic_price_table .generic_content {
    background-color: #fff;
  }
  
  #generic_price_table .generic_content .generic_head_price {
    background-color: #f6f6f6;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head_bg {
    border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head
    span {
    color: #525252;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .sign {
    color: #414141;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .currency {
    color: #414141;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .cent {
    color: #414141;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .month {
    color: #414141;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li {
    color: #a7a7a7;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li span {
    color: #414141;
  }
  #generic_price_table .generic_content .generic_feature_list ul li:hover {
    background-color: #e4e4e4;
    border-left: 5px solid #ff5252;
  }
  
  #generic_price_table .generic_content .generic_price_btn a {
    border: 1px solid #ff5252;
    color: #ff5252;
  }
  
  #generic_price_table
    .generic_content.active
    .generic_head_price
    .generic_head_content
    .head_bg,
  #generic_price_table
    .generic_content:hover
    .generic_head_price
    .generic_head_content
    .head_bg {
    border-color: #ff5252 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ff5252;
    color: #fff;
  }
  
  #generic_price_table
    .generic_content:hover
    .generic_head_price
    .generic_head_content
    .head
    span,
  #generic_price_table
    .generic_content.active
    .generic_head_price
    .generic_head_content
    .head
    span {
    color: #fff;
  }
  
  #generic_price_table .generic_content:hover .generic_price_btn a,
  #generic_price_table .generic_content.active .generic_price_btn a {
    background-color: #ff5252;
    color: #fff;
  }
  #generic_price_table {
    margin: 5px 0 5px 0;
    font-family: "Raleway", sans-serif;
  }
  .row .table {
    padding: 28px 0;
  }
  
  /*PRICE BODY CODE START*/
  
  #generic_price_table .generic_content {
    overflow: hidden;
    position: relative;
    text-align: center;
    /* background-color: red; */
    border: 2px solid #f6f6f6;
  }
  
  #generic_price_table .generic_content .generic_head_price {
    margin: 0 0 20px 0;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content {
    margin: 0 0 50px 0;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head_bg {
    border-style: solid;
    border-width: 90px 1411px 23px 399px;
    position: absolute;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head {
    padding-top: 40px;
    position: relative;
    z-index: 1;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head
    span {
    font-family: "Raleway", sans-serif;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  
  #generic_price_table .generic_content .generic_head_price .generic_price_tag {
    padding: 0 0 20px;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price {
    display: block;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .sign {
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-size: 28px;
    font-weight: 400;
    vertical-align: middle;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .currency {
    font-family: "Lato", sans-serif;
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 60px;
    padding: 0;
    vertical-align: middle;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .cent {
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-weight: 400;
    vertical-align: bottom;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .month {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    padding: 15px 0;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table .generic_content .generic_feature_list ul li:hover {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table .generic_content .generic_feature_list ul li .fa {
    padding: 0 10px;
  }
  #generic_price_table .generic_content .generic_price_btn {
    margin: 20px 0 32px;
  }
  
  #generic_price_table .generic_content .generic_price_btn a {
    border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    -webkit-border-radius: 50px;
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    outline: medium none;
    padding: 12px 30px;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  #generic_price_table .generic_content,
  #generic_price_table .generic_content:hover,
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head_bg,
  #generic_price_table
    .generic_content:hover
    .generic_head_price
    .generic_head_content
    .head_bg,
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head
    h2,
  #generic_price_table
    .generic_content:hover
    .generic_head_price
    .generic_head_content
    .head
    h2,
  #generic_price_table .generic_content .price,
  #generic_price_table .generic_content:hover .price,
  #generic_price_table .generic_content .generic_price_btn a,
  #generic_price_table .generic_content:hover .generic_price_btn a {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
  }
  @media (max-width: 320px) {
  }
  
  @media (max-width: 767px) {
    #generic_price_table .generic_content {
      margin-bottom: 75px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    #generic_price_table .col-md-3 {
      float: left;
      width: 50%;
    }
  
    #generic_price_table .col-md-4 {
      float: left;
      width: 50%;
    }
  
    #generic_price_table .generic_content {
      margin-bottom: 75px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) {
  }
  #generic_price_table_home {
    font-family: "Raleway", sans-serif;
  }
  
  .text-center h1,
  .text-center h1 a {
    color: #7885cb;
    font-size: 30px;
    font-weight: 300;
    text-decoration: none;
  }
  .demo-pic {
    margin: 0 auto;
  }
  .demo-pic:hover {
    opacity: 0.7;
  }
  
  #generic_price_table_home ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: table;
  }
  #generic_price_table_home li {
    float: left;
  }
  #generic_price_table_home li + li {
    margin-left: 10px;
    padding-bottom: 10px;
  }
  #generic_price_table_home li a {
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0px;
  }
  #generic_price_table_home .blue {
    background: #3498db;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .emerald {
    background: #ff5252;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .grey {
    background: #7f8c8d;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .midnight {
    background: #34495e;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .orange {
    background: #e67e22;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .purple {
    background: #9b59b6;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .red {
    background: #e74c3c;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .turquoise {
    background: #1abc9c;
    transition: all 0.3s ease-in-out 0s;
  }
  
  #generic_price_table_home .blue:hover,
  #generic_price_table_home .emerald:hover,
  #generic_price_table_home .grey:hover,
  #generic_price_table_home .midnight:hover,
  #generic_price_table_home .orange:hover,
  #generic_price_table_home .purple:hover,
  #generic_price_table_home .red:hover,
  #generic_price_table_home .turquoise:hover {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    transition: all 0.3s ease-in-out 0s;
  }
  #generic_price_table_home .divider {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 20px;
  }
  #generic_price_table_home .divider span {
    width: 100%;
    display: table;
    height: 2px;
    background: #ddd;
    margin: 50px auto;
    line-height: 2px;
  }
  #generic_price_table_home .itemname {
    text-align: center;
    font-size: 50px;
    padding: 50px 0 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    text-decoration: none;
    font-weight: 300;
  }
  #generic_price_table_home .itemnametext {
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    text-transform: uppercase;
    display: inline-block;
  }
  #generic_price_table_home .footer {
    padding: 40px 0;
  }
  
  .price-heading {
    text-align: center;
  }
  .price-heading h1 {
    color: #666;
    margin: 0;
    padding: 0 0 50px 0;
  }
  .demo-button {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    outline-color: -moz-use-text-color;
    outline-style: none;
    outline-width: medium;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
  }
  .bottom_btn {
    background-color: #333333;
    color: #ffffff;
    display: table;
    font-size: 28px;
    margin: 60px auto 20px;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
  }
  .demo-button:hover {
    background-color: #666;
    color: #fff;
    text-decoration: none;
  }
  .bottom_btn:hover {
    background-color: #666;
    color: #fff;
    text-decoration: none;
  }
  </style>