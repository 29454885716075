<template>
    <v-container>
        <p class="text-center text--secondary" style="font-size: 15px">
            Atualizar os dados da sua empresa.
          </p>
          <v-card tile elevation="0" outlined>
            <v-card-title>
              <h4 class="heading-4 accent--text" color="#FF5252">Dados da Empresa</h4>
            </v-card-title>

            <v-card-text>
              <v-row wrap>
                <v-col cols="12" md="6">
                  <v-text-field label="Nome da Empresa" outlined dense v-model="empresa.nome"
                    color="#ff5252"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="empresa.industria" dense outlined :items="industrias" item-text="name"
                    item-value="id" label="Industria" placeholder="Selecione o Industria" color="#ff5252"></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-6" tile elevation="0" outlined>
            <v-card-title>
              <h4 class="heading-4 accent--text">Sobre a Empresa</h4>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <vue-editor v-model="empresa.sobre_nos" label="Descreva sobre a Empresa" outlined dense
                    :editor-toolbar="customToolbar"></vue-editor>
                </v-col>
              </v-row>
              <v-row wrap class="mt-6">
                    <v-col cols="12">
                    <h3 class="accent--text">SERVIÇOS QUE SUA EMPRESA OFERECE</h3>
                    </v-col>
                </v-row>
                <v-row wrap>
                    <v-col cols="12" md="6" v-for="(household, index) in servicos" :key="index">
                    <v-card class="mb-4" tile elevation="0" outlined>
                        <v-card-title>
                            <h5 class="heading-5 grey--text">Serviço {{ index + 1 }}</h5>
                        </v-card-title>
                        <v-card-text>
                            <v-row wrap>
                                <v-col cols="12">
                                    <v-file-input
                                            accept="image/*"
                                            prepend-icon="mdi-camera-image"
                                            placeholder="Uma imagem que representa o serviço (opcional)"
                                            @change="(e) => {servicos[index].iamge = e}">
                                    </v-file-input>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="servicos[index].nome" label="Nome do Serviço" outlined dense required>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <vue-editor v-model="servicos[index].descricao" label="Descrição do Serviço" outlined required :editor-toolbar="customToolbar">
                                    </vue-editor>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                        <v-btn depressed rounded outlined color="secondary" small @click.prevent="removeHousehold(index)" v-show="index || (!index && servicos.length > 1)">
                            Remover Serviço
                        </v-btn>
                        <v-btn rounded color="secondary" small @click.prevent="addHousehold(index)" v-show="index == servicos.length - 1">
                            Adicionar Serviço
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-col>
                </v-row>
                <v-row wrap>
                    <!-- <v-card class="mt-6" tile elevation="0" outlined> -->

                    <v-card-title>
                    <h4 class="heading-4 accent--text" color="#FF5252">Contactos da Empresa</h4>
                    </v-card-title>
                    <v-card-text>
                    <v-row>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4" v-for="(item, index) in contactos" :key="index">

                                    <v-text-field v-model="contactos[index].telefone" label="Número de Telefone" outlined dense>

                                        <v-icon slot="prepend" color="#E61F31" @click.prevent="removeContactos(index)"
                                        v-show="index || (!index && contactos.length > 1)">

                                        mdi-minus</v-icon>

                                        <v-icon slot="append" color="#FF5252" @click.prevent="addContactos(index)"
                                        v-show="index == contactos.length - 1">

                                        mdi-plus</v-icon>

                                    </v-text-field>

                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field v-model="empresa.email_cotacao" label="E-mail para Cotação" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="empresa.website" label="Website" outlined dense></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-row>
                    </v-card-text>
                <!-- </v-card> -->
                <v-card-title>
                    <h4 class="heading-4 accent--text">Endereço da Empresa</h4>
                </v-card-title>
                    <v-card-text>

                    <v-row>

                        <v-col cols="12" md="4">

                        <v-select v-model="empresa.province_id" dense outlined :items="provinces" item-text="name"
                            item-value="map_code" label="Provincia" placeholder="Selecione a Provincia"
                            @change="changeProvince"></v-select>

                        </v-col>

                        <v-col cols="12" md="4">

                        <v-select v-model="empresa.district_id" dense outlined :items="districts.data" item-text="name"
                            item-value="id" label="Distrito" placeholder="Selecione o Distrito"></v-select>

                        </v-col>

                        <v-col cols="12" md="4">

                        <v-text-field v-model="empresa.localizacao" label="Localização" outlined dense></v-text-field>

                        </v-col>

                    </v-row>

                    </v-card-text>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="#FF5414" dark small @click="updateSubmite">Acualizar</v-btn>
                <v-btn color="#FF5414" dark small :to="{name: 'empresa.show', params: empresa.id}">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
          
          <div class="" style="height: 80px;"></div>
    </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";

export default {
    components: {
        VueEditor
    },
    props: {
        
        
    },
    data: () => {
        return {
            districts: [],
            emails: [
                {
                    email: "",
                },
            ],
            contactos: [
                {
                    telefone: "",
                },
            ],
            servicos: [
                {
                id: null,
                image: null,
                nome: "",
                descricao: "",
                },
            ],
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                ],
                [{ list: "bullet" }, { list: "ordered" }, { list: "check" }],
                [{ color: [] }],
                ["link"],
            ],
            industrias: [
                "Administração e Secretariado Cliente",
                "Aquisições e Procurement",
                "Assistente",
                "Auditoria e Consultoria",
                "Comunicação Social",
                "Construção Civil",
                "Contabilidade e Finanças",
                "Design",
                "Direito e Justiça",
                "Electricidade",
                "Gestão de Projectos",
                "Gestão e Assessoria",
                "Gestão Financeira",
                "Gestor Provincial",
                "Higiene e Segurança",
                "Hotelaria e Turismo",
                "informática e Programação",
                "Manutenção e Técnica",
                "Marketing e Publicidade",
                "Mecânica",
                "Meio Ambiente",
                "Monitoria e Avaliação",
                "Oficial Técnico",
                "Operário",
                "Recursos",
                "Humanos",
                "Relações Públicas",
                "Saúde",
                "Seguros",
                "Serviços Gerais",
                "Serviços Sociais",
                "Supervisão e Coordenação",
                "Topografia e Geologia",
                "Transportes e Logística",
                "Vendas/Comércio",
                "Outro",
            ],
            empresa: {
                id: "",
                image: null,
                nome: "",
                industria: "",
                email: "",
                telefone: "",
                provincia: "",
                district_id: null,
                province_id: null,
                localizacao: "",
                servicos: [],
                sobre_nos: "",
                email_cotacao: "",
                website: "",
            }
        }
    },
    created() {
        // console.log(this.$route.params.id);
        this.getEmpresa(this.$route.params.id)
        this.getProvinces()
    },
    methods: {
        getEmpresa(id) {
            this.$store.dispatch("getEmpresaWithAuth", {id}).then((res) => {
                this.empresa = res.data.data; 
                // console.log(res.data.data.services.length);
                // this.servicos = res.data.data.services
                this.contactos = JSON.parse(res.data.data.telefone);
                this.empresa.province_id = res.data.data.district.province_id
                if (res.data.data.services.length > 0) {
                    this.servicos = this.res.data.data.services
                    // console.log(res.data.data.services);
                } else {
                    // console.log(res.data.data.services);
                    this.servicos = JSON.parse(res.data.data.servicos)
                }
                // console.log(res.servicos);
            });
        },
        updateSubmite(){
            // console.log('ok');
            const params = {
                id: this.empresa.id,
                nome: this.empresa.nome,
                industria: this.empresa.industria,
                website: this.empresa.website,
                district_id: this.empresa.district_id,
                localizacao: this.empresa.localizacao,
                email_cotacao: this.empresa.email_cotacao,
                sobre_nos: this.empresa.sobre_nos,
                telefone: JSON.stringify(this.contactos)
            }
            // console.log(params);
            this.$store.dispatch('updateEmpresa', params)
                .then(res => {
                    this.servicos.forEach((serv) => {
                        const formData = new FormData();
                        if (serv.id !== undefined && serv.id !== null) {
                            formData.append('id', serv.id);
                        }
                        formData.append('nome', serv.nome);
                        formData.append('descricao', serv.descricao);
                        formData.append('empresa_id', this.empresa.id);
                        if (serv.image !== null && typeof serv.image !== 'string') {
                            formData.append("image", serv.image);
                        }
                        this.$store.dispatch('updateService', formData).then(res => {
                            Swal.fire({
                                title: "Sucesso!",
                                text: "Empresa atualizada com sucesso.",
                                icon: "success",
                                confirmButtonText: "OK",
                                confirmButtonColor: "#FF4500",
                            }).then(() => {
                                
                                this.$router.push({name: 'empresa.show', params: {id: this.$route.params.id}})
                            });
                        })
                    })
                    
                })
        },
        getProvinces() {
            this.$store.dispatch("getProvince");
        },
        changeProvince(id) {
            this.$store.dispatch("getDistricts", id).then((res) => {
                this.districts = res.data;
            });
        },
        addHousehold() {
            this.servicos.push({
                nome: "",
                descricao: "",
                image: null
            });
        },
        removeHousehold(index) {
            this.servicos.splice(index, 1);
        },
        addEmails() {
            this.emails.push({
                email: "",
            });
        },
        addContactos() {
            this.contactos.push({
                telefone: "",
            });
        },
        removeEmails(index) {
            this.emails.splice(index, 1);
        },
        removeContactos(index) {
            this.contactos.splice(index, 1);
        },
    },
    computed: {
        provinces() {
            return this.$store.state.provinces;
        },
    },
    watch: {
        empresa(empresa) {
            if (empresa.province_id) {
                this.$store.dispatch("getDistricts", empresa.province_id).then((res) => {
                    this.districts = res.data;
                });
            }
        }
    }
}
</script>

<style>

</style>